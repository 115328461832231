import { useParams } from 'react-router';
import { SlideGroup } from '../../../api/slideSet';
import api from '../../../services/api';

type UseGroupActionsParams = {
  fetchData: () => Promise<SlideGroup[]>;
  groups: SlideGroup[];
  setGroups: (groups: SlideGroup[]) => void;
};

export default function useGroupActions({ fetchData, groups, setGroups }: UseGroupActionsParams) {
  const { moduleId, nodeId, setId, versionId } = useParams();
  async function save(newData: (SlideGroup | (Omit<SlideGroup, 'id' | 'linkId' | 'previewText'> & { id: null }))[]) {
    const data = newData
      .map(group =>
        group.slides.length
          ? group.slides.map(slide => ({ groupId: group.id, slideId: slide.id }))
          : [{ groupId: group.id, slideId: null }],
      )
      .flat();

    return api
      .put(
        `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/move`,
        { groups: data },
      )
      .then(fetchData)
      .then(setGroups);
  }

  const addSlideToGroup = async (
    originalGroupId: number,
    targetGroupId: number,
    movedSlide: SlideGroup['slides'][number],
  ) => {
    if (originalGroupId === targetGroupId) return;
    const updated = groups.reduce<SlideGroup[]>((newGroups, group) => {
      if (group.id === originalGroupId) {
        if (group.type === 'SINGLE') return newGroups;

        return [
          ...newGroups,
          {
            ...group,
            slides: group.slides.filter(slide => slide.id !== movedSlide.id),
          },
        ];
      }
      if (group.id === targetGroupId) {
        return [
          ...newGroups,
          {
            ...group,
            slides: [...group.slides, movedSlide],
          },
        ];
      }

      return [...newGroups, group];
    }, []);

    save(updated);
  };

  const reorderGroupSlides = (groupId: number, slides: SlideGroup['slides'][number][]) => {
    const updated = groups.map(group => {
      if (group.id === groupId) {
        return {
          ...group,
          slides,
        };
      }

      return group;
    });

    save(updated);
  };

  const reorderGroups = (originalGroup: SlideGroup, targetGroup: SlideGroup, direction: 'up' | 'down') => {
    const originalIndex = groups.findIndex(group => group.id === originalGroup.id);
    let targetIndex = groups.findIndex(group => group.id === targetGroup.id);

    if (direction === 'down' && originalIndex > targetIndex) {
      targetIndex += 1;
    } else if (direction === 'up' && originalIndex < targetIndex && targetIndex !== 0) {
      targetIndex -= 1;
    }

    const updated = [...groups];
    updated.splice(targetIndex, 0, ...updated.splice(originalIndex, 1));

    save(updated);
  };

  const moveGroupSlideToSingleGroup = (
    movedGroup: SlideGroup,
    movedSlide: SlideGroup['slides'][number],
    targetGroup: SlideGroup,
    direction: 'up' | 'down',
  ) => {
    const targetIndex = groups.findIndex(group => group.id === targetGroup.id);
    const updated = groups.map<SlideGroup | (Omit<SlideGroup, 'id' | 'linkId' | 'previewText'> & { id: null })>(
      group => ({
        ...group,
        slides: group.id === movedGroup.id ? group.slides.filter(slide => slide.id !== movedSlide.id) : group.slides,
      }),
    );

    const offset = direction === 'down' ? 1 : 0;
    updated.splice(targetIndex + offset, 0, {
      id: null,
      type: 'SINGLE',
      slides: [movedSlide],
    });

    save(updated);
  };

  return {
    addSlideToGroup,
    reorderGroupSlides,
    reorderGroups,
    moveGroupSlideToSingleGroup,
  };
}
