import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SlideGroup, getSlideGroups } from '../../../api/slideSet';
import { getSlideSetVersion } from '../../../api/slideSetVersion';

const SlidesetVersionGroupsContext = createContext<{
  groups: SlideGroup[];
  setGroups: (groups: SlideGroup[]) => void;
  fetchData: () => Promise<SlideGroup[]>;
}>({
  groups: [],
  setGroups: () => {},
  fetchData: async () => [],
});

export function SlidesetVersionGroupsContextProvider({ children }) {
  const [groups, setGroups] = useState<SlideGroup[]>([]);
  const { setId, versionId, nodeId, moduleId } = useParams();
  const navigate = useNavigate();

  const validateRights = useCallback(async () => {
    if (!moduleId || !nodeId || !setId || !versionId) return;
    try {
      const { state } = await getSlideSetVersion(moduleId, nodeId, setId, versionId);
      if (state === 'ARCHIVED' || state === 'PUBLISHED') navigate('/digislides');
    } catch (e) {
      navigate('/digislides');
    }
  }, [moduleId, nodeId, setId, versionId, navigate]);

  const fetchData = useCallback(async () => {
    if (!moduleId || !nodeId || !setId || !versionId) {
      throw new Error('Can not use SlidesetVersionDetail outside of module');
    }
    try {
      const slideGroups = await getSlideGroups(moduleId, nodeId, setId, versionId);
      return slideGroups;
    } catch (e) {
      navigate('/digislides');
      return [];
    }
  }, [moduleId, nodeId, setId, versionId, navigate]);

  useEffect(() => {
    let cancelled = false;

    validateRights();

    fetchData().then(data => {
      if (!cancelled) setGroups(data);
    });

    return () => {
      cancelled = true;
    };
  }, [validateRights, fetchData, setGroups]);

  const contextValue = useMemo(() => ({ groups, setGroups, fetchData }), [groups, setGroups, fetchData]);

  return <SlidesetVersionGroupsContext.Provider value={contextValue}>{children}</SlidesetVersionGroupsContext.Provider>;
}

export function useSlidesetVersionGroups() {
  return useContext(SlidesetVersionGroupsContext);
}
